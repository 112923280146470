import React from 'react'

import logo from './logo.svg';
import styles from './App.module.css';
import { Route, Switch } from 'react-router';
import { Home, NavBar,LandingPage } from './Components';


class App extends React.Component{
  render(){
    return(
      <div className={styles.container} id="main">
        <div className={styles.navBar}> 
          <NavBar/>
        </div>
        <Switch>
          <Route exact path='/' render={props=><LandingPage {...props} />}/>
          
        </Switch>
      </div>
    )
  }
}

export default App;
