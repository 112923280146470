import React, { useEffect, useState } from "react"
import styles from "./About.module.css"
import Subin from "./subin.jpg"
import Particles from "react-particles-js"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const About=()=>{
    const [aboutPos,setAboutPos]=useState(100)
    useEffect(()=>{
        var a= document.getElementById("main")
        
        a.addEventListener('scroll', onScroll)
        return () => a.removeEventListener('scroll', onScroll)
    },[])
    const onScroll=()=>{
        var ab= document.getElementById("main")
       
            var a= document.getElementById("about")
            var {top,height,width}=a.getBoundingClientRect()
           
            setAboutPos((top/window.innerHeight)*100 )
    }
    var TxtRotate = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 2000;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
      };
      
      TxtRotate.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];
      
        if (this.isDeleting) {
          this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
          this.txt = fullTxt.substring(0, this.txt.length + 1);
        }
      
        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
      
        var that = this;
        var delta = 300 - Math.random() * 100;
      
        if (this.isDeleting) { delta /= 2; }
      
        if (!this.isDeleting && this.txt === fullTxt) {
          delta = this.period;
          this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
          this.isDeleting = false;
          this.loopNum++;
          delta = 500;
        }
      
        setTimeout(function() {
          that.tick();
        }, delta);
      };
      
      window.onload = function() {
        var elements = document.getElementsByClassName('txt-rotate');
        for (var i=0; i<elements.length; i++) {
          var toRotate = elements[i].getAttribute('data-rotate');
          var period = elements[i].getAttribute('data-period');
          if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
          }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
        document.body.appendChild(css);
      };

    return(
        <div className={styles.container} style={{opacity:(0<(1/aboutPos))?(1/aboutPos):1}}>
            <div className={styles.top} id="about" >
               <div style={{width:`${100-aboutPos}%`}}>
               About
                </div> 
               
            </div>
            <div className={styles.paper}>

            <div className={styles.row}>
           { console.log(1/aboutPos)}
                <div className={styles.verySub}>
                
                <span
                    class="txt-rotate"
                    data-period="2000"
                    data-rotate='[ "MERN STACK Developer.", "Software Engineering Student", "Football for Life", "I Love Travelling around."  ]'></span>
            </div>
            <div className={styles.right}>
                <img src={Subin} className={styles.img}/>
            </div>
            <div className={styles.absoluteDiv}>
                <Particles params={{
	    "particles": {
	        "number": {
	            "value": 20,
	            "density": {
	                "enable": false
	            }
	        },"color":"#b9aa229a",
	        "size": {
	            "value": 9,
	            "random": true
	        },
	        "move": {
	            "direction": "top",
	            "out_mode": "out"
	        },
	        "line_linked": {
	            "enable": false
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onclick": {
	                "enable": true,
	                "mode": "remove"
	            }
	        },
	        "modes": {
	            "remove": {
	                "particles_nb": 7
	            }
	        }
	    }
	}}/>
            </div>
            </div>
            <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#0000', color: '#d6af7c' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="Sept 4,1998"
    iconStyle={{ background: '#d6af7c', color: '#d6af7c' }}
    
  >
    <h3 className="vertical-timeline-element-title">Born</h3>
    <h4 className="vertical-timeline-element-subtitle">Pokhara, Nepal</h4>
   
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#0000', color: '#d6af7c' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="2001-2014"
    iconStyle={{ background: '#d6af7c', color: '#d6af7c' }}
    
  >
    <h3 className="vertical-timeline-element-title">School</h3>
    <h4 className="vertical-timeline-element-subtitle">Saint Francis School</h4>
    <p>
      
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#0000', color: '#d6af7c' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="2014-2016"
    iconStyle={{ background: '#d6af7c', color: '#d6af7c' }}
    
  >
    <h3 className="vertical-timeline-element-title">High School</h3>
    <h4 className="vertical-timeline-element-subtitle">Sagarmatha Higher Secondary School</h4>
    <p>
      Science Faculty
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
   className="vertical-timeline-element--work"
   contentStyle={{ background: '#0000', color: '#d6af7c' }}
   contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
   date="2016- running"
   iconStyle={{ background: '#d6af7c', color: '#d6af7c' }}
  >
    <h3 className="vertical-timeline-element-title">Bachelors</h3>
    <h4 className="vertical-timeline-element-subtitle">Gandaki College of Engineering & Science</h4>
    <p>
     Software Engineering
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#0000', color: '#d6af7c' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="2020- present"
    iconStyle={{ background: '#d6af7c', color: '#d6af7c' }}
  
  >
    <h3 className="vertical-timeline-element-title">Senior Software Developer</h3>
    <h4 className="vertical-timeline-element-subtitle">Black Tech PVT. LTD.</h4>
    <p>
      Working as full-time MERN Stack Developer
    </p>
  </VerticalTimelineElement>
 
  <VerticalTimelineElement
    iconStyle={{ background: '#d6af7c', color: '#fff' }}
    
  />
</VerticalTimeline>
                </div>
        </div>
    )
}
export default About