import React from 'react'
import styles from './Home.module.css'
import Particles from "react-particles-js"
import Deer from "./pic.svg"
import Subin from "./subin2.jpg"
const Home=()=>{
  
    return(
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.mainHeader}>
                    Hello.
                </div>
                <div className={styles.subName}>
                    I am
                     <div className={styles.glitch}>
                          Subin
                        </div> 
                </div>
                {/* <div className={styles.verySub}>
                
                    <span
                        class="txt-rotate"
                        data-period="2000"
                        data-rotate='[ "MERN STACK Developer.", "Software Engineering Student", "Loves Football", "Traveller!" ]'></span>
                </div> */}
            </div>
            <div className={styles.right}>
                <div>

                
            <Particles width="40vw" height="80vh"
    params={{
	    "particles": {
	        "number": {
	            "value": 20
	        },
	        "size": {
	            "value": 2
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onhover": {
	                "enable": true,
	                "mode": "repulse"
	            }
	        }
	    }
	}}  />
    </div>
       
            </div>
            </div>
    )
}

export default Home