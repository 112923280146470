import React from "react"
import styles from "./NavBar.module.css"

const NavBar=()=>{
    return(
        <div className={styles.container}>
            <div className={styles.space}>

             <div>
                About
            </div>
            <div>
                Works
            </div>
            <div>
                Contact
            </div>
            </div>
         
        </div>
    )
}
export default NavBar