import React from "react"
import { About, Home } from ".."
import styles from "./LandingPage.module.css"

const LandingPage=()=>{
    return(
        <div className={styles.container}>
           <Home/>
            <About/>
          
        </div>
    )
}
export default LandingPage